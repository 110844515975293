<template>
  <div class="register">
    <img
      class="register__img"
      alt="logo-prego"
      src="../assets/image/logo-prego.png"
    />
    <div class="register__div">
      <button class="item">
        <img
          class="item__img"
          alt="icon-line"
          src="../assets/image/icon-line.png"
        />
        <div class="item__div f-w3">LINEで登録</div>
      </button>
      <button class="item">
        <img
          class="item__img"
          alt="icon-facebook"
          src="../assets/image/icon-facebook.png"
        />
        <div class="item__div f-w3">Facebookで登録</div>
      </button>
      <button class="item">
        <img
          class="item__img"
          alt="icon-mail"
          src="../assets/image/icon-mail.png"
        />
        <div class="item__div f-w3">Eメールで登録</div>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Register"
};
</script>

<style lang="scss">
@import "../assets/scss/castRegister.scss";
@import "../assets/scss/_fontFamily.scss";
</style>
