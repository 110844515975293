<template>
  <div id="cast-register">
    <cast-register-body />
  </div>
</template>

<script>
// @ is an alias to /src
import CastRegister from "@/components/CastRegister.vue";

export default {
  name: "Register",
  components: {
    "cast-register-body": CastRegister
  }
};
</script>
